// ------------------------------------------------------- guest nav style ----------------------------------------------------------------
#guest {
  .h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    color: #2ca5cd;
  }
  .chicon-nav {
    height: 5rem !important;
    margin-bottom: 2rem !important;
  }
  // .ch-navhd{
  // padding-left: 10rem;
  // padding-right: 10rem;
  // .nav-link{
  // color: #2d5c88 !important;
  // }

  .ch-navhd {
    a.nav.link:hover {
      border-bottom: 2px solid brown !important;
    }
    padding: 1rem 1rem !important;
    background-color: #fff0 !important;
  }

  // ----------------------------------------------ramanathancss-----------------------------------------------

  .parent-h1 {
    text-align: center;
    // / line-height: 20px; /
    color: #0680bd;
  }

  .slide-res {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
  .slide-res span {
    font-size: 10px;
  }
  .slide-res p {
    font-size: 12px;
    margin-top: 1rem;
  }
  .latest-p {
    text-align: justify;
  }
  .img-icon {
    height: 6rem;
  }
  .img-icon img {
    transform: rotate(12deg);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
  .pin-box {
    width: 30px;
    position: absolute;
    right: 35px;
    top: 10px;
    height: 30px;
  }
  .stick-note {
    transform: rotate(-13deg);
    margin-bottom: 2rem;
  }
  .stick-note1 {
    padding-top: 4rem;
  }
  .guest-pad {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .gust-fl {
    float: left;
  }
  .content-box {
    height: 12rem;
    margin-bottom: 1rem;
  }
  .news {
    padding: 20px;
    background-image: linear-gradient(to bottom right, #b3d1d3, #fcfcfc);
  }

  // -------------------------------------Dheena-----------------------------------------

  .services-small-item {
    display: block;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 23px 15px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
  }

  .services-small-item:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 5px;
    background-image: linear-gradient(135deg, #80d0c7 0%, #13547a 100%);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
  }
  .services-small-item.active:before,
  .services-small-item:hover:before {
    opacity: 1;
  }

  .services-small-item.active .services-title,
  .services-small-item:hover .services-title {
    color: #ffffff;
  }

  .services-small-item.active.vendor,
  .services-small-item:hover .vendor {
    color: #ffffff;
  }

  .services-small-item.active,
  .services-small-item {
    background-image: linear-gradient(135deg, #fff 0%, #fff 100%) !important;
    color: #2b708b !important;
  }
  .water-001 {
    background-image: linear-gradient(135deg, #fff 0%, #fff 100%) !important;
    color: #2b708b !important;
  }

  .services-small-item:hover {
    margin-top: -15px;
  }

  .services-small-item .icon-001 {
    width: 139px;
    height: 111px;
    margin: auto;
    position: relative;
    margin-bottom: -35px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .services-small-item .icon-001 .water-001 {
    display: block;
    height: 67px;
    line-height: 67px;
    position: absolute;
    width: 100%;
    top: 22px;
    z-index: 2;
    font-size: 28px;
    background-image: linear-gradient(135deg, #80d0c7 0%, #13547a 100%);

    -webkit-text-fill-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .services-small-item {
    font-weight: 400;
    font-size: 16px;
    color: #3b566e;
    letter-spacing: 0.7px;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .services-title {
    font-weight: 400;
    font-size: 20px;
    color: #3b566e;
    letter-spacing: 0.7px;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .services-small-item .vendor {
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    letter-spacing: 0.88px;
    line-height: 26px;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-bottom: 24px;
    text-align: start;
  }

  .button-design {
    position: relative;
    z-index: 2;
  }
  .button-design2 {
    border: none;
    border-radius: 25px;
  }

  .services-small-item .button-design .water-001 {
    width: 38px;
    height: 42px;
    background-image: linear-gradient(135deg, #80d0c7 0%, #13547a 100%);
    line-height: 42px;
    text-align: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    color: #fff;
    padding-left: 3px;
  }

  .services-small-item:hover + {
    bottom: 30px;
  }
  .main {
    padding-top: 5rem;
  }
  .cont-box {
    height: 266px;
  }

  .retial-cons-001 {
    color: #ffff;
    padding: 8px 40px;
    background-color: #005a87;
    border: none;
    margin-top: 3rem;
    font-size: 18px;
    border-radius: 4px;
  }
  .retial-cons-002 {
    color: #2ca5cd;
    font-size: 20px;
    line-height: 1.1em;
    margin-bottom: 8px;
    font-weight: 600;
    margin-top: 2rem;
    font-family: "open sans", "HelveticaNeue", "Helvetica Neue",
      "Helvetica-Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
  }
  .fa-001 {
    padding: 4px;
  }

  .sec1 {
    // background-image: url(https://channelhub.net/wp-content/uploads/2018/09/header-channelhub-e1538039903883.jpg);
    // background-repeat: no-repeat !important;
    // height: 600px;

    @media only screen and (max-width: 600px) {
      height: 355px !important;
    }
    .sec2 {
      width: 0px;
      opacity: 0.4;
      background-color: #013150;
      height: 600px;
      // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
      @media only screen and (max-width: 600px) {
        height: 355px !important;
      }
      h1 {
        margin: 0px !important;
        color: aliceblue;
      }
      h3 {
        color: #2ca5cd;
      }
      p {
        color: aliceblue;
      }
    }
  }
  .guest-vid {
    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .navi {
    z-index: 1;
    li a {
      color: #13547a !important;
    }
    height: 104px;
    margin-top: 1rem;
  }
}
.gushead {
  padding: 10rem !important;
}

.slide {
  position: relative;
  top: 2.5rem;
}
.gabout {
  width: 100% !important;
  min-height: 25rem;
  background: url(../../img/guestpage/about.jpg) no-repeat;
  background-size: 100%;
  // position: relative;
  // top: -8rem;
  z-index: -1;
  .gabout1 {
    position: absolute;
    width: 100%;
    min-height: 25rem;
    opacity: 0.8;
    right: 0;
    overflow: hidden;
    padding: 6.5rem 0;
    background: #d0ece9;
    -webkit-clip-path: polygon(
      100% 0%,
      100% 48%,
      100% 100%,
      25% 100%,
      0% 50%,
      25% 0%
    );
    clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    animation: mymove 5s;
  }
  // .gsec2:hover .gsec1{
  //   opacity: 0.8;
  //   transition: width 2s;
  //   width: 100%;
  //   background: rgb(208, 236, 233);
  //   clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
  // }
  @keyframes mymove {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
  .gabout3 {
    width: 100%;
    height: 100%;
    padding: 0rem 5rem;
    background-color: rgb(244, 240, 210);
    overflow: hidden !important;
    opacity: 1;
    animation: mymove1 5s;
  }
  @keyframes mymove1 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    85% {
      opacity: 0;
    }
    91% {
      opacity: 0.1;
    }
    92% {
      opacity: 0.2;
    }
    93% {
      opacity: 0.3;
    }
    94% {
      opacity: 0.4;
    }
    95% {
      opacity: 0.5;
    }
    96% {
      opacity: 0.6;
    }
    97% {
      opacity: 0.7;
    }
    98% {
      opacity: 0.8;
    }
    99% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
}
.profad {
  background: rgb(208, 236, 233);
}

.testmonial {
  background-color: #fff;
  //   height: 47rem;
  // display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;
}
.testmonial::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #47aafa;
  clip-path: circle(35% at 0% 100%);
}
.testmonial::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ef3b36;
  clip-path: circle(65% at 100% -20%);
}

.cardmonial {
  height: 27rem !important;
}

.contentsec {
  width: 100% !important;
  height: 600px;
  position: relative;
  background-size: 100%;
  .contentsec1 {
    width: 0;
    height: 600px;
    right: 0;
    overflow: hidden;
    padding: 9.5rem 0;
  }
  .contentsec2:hover .contentsec1 {
    opacity: 0.8;
    transition: width 2s;
    width: 100%;
    background: rgb(208, 236, 233);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    left: 100%;
    transform: translate(100%, 0%);
  }
  .contentsec3 {
    width: 100%;
    height: 100%;
    padding: 4rem 5rem;
    background-color: rgb(244, 240, 210);
    overflow: hidden !important;
  }
}

.channelimg {
  width: 119px;
  background-color: #70bdce;
  height: 118px;
  margin-top: 2rem;
}
.channelcard {
  height: 35rem;
}
.iconbg {
  background-color: #70bdce;
  padding: 7px 7px 22px 7px;
}
.businet {
  li a {
    color: #525f7f;
  }
  li a:hover {
    color: #47aafa;
  }
}

.retial-cons {
  text-align: center !important;
  img {
    width: 280px !important;
  }
}
.testclip {
  clip-path: polygon(
    0% 0%,
    0 100%,
    25% 100%,
    26% 26%,
    75% 25%,
    75% 75%,
    25% 75%,
    23% 100%,
    100% 100%,
    100% 0%
  );
}
.top-import {
  width: 100%;
  height: 26rem;
  h4,
  h1 {
    color: #fff;
  }
  .clip-divf {
    height: 10rem;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    background-color: #2c9db75e;
    position: relative;
    bottom: 8.6rem;
  }
}
.footer-copyright {
  position: relative;
  top: 0;
  z-index: 1;
}
.whatdo {
  background-color: #e3f0f5;
  padding: 4rem;
}
.gsec {
  width: 100% !important;
  height: 666px;
  background: url(../../img/guestpage/header.jpg.jpg) no-repeat;
  background-size: 100%;
  // position: relative;
  // top: -8rem;
  z-index: -1;
  .gsec1 {
    width: 100%;
    height: 606px;
    opacity: 0.8;
    right: 0;
    overflow: hidden;
    padding: 8.5rem 0;
    background: #d0ece9;
    -webkit-clip-path: polygon(
      75% 0%,
      100% 50%,
      75% 100%,
      0% 100%,
      0 53%,
      0% 0%
    );
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
    animation: mymove 5s;
    margin-top: -3rem;
  }
  // .gsec2:hover .gsec1{
  //   opacity: 0.8;
  //   transition: width 2s;
  //   width: 100%;
  //   background: rgb(208, 236, 233);
  //   clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
  // }
  @keyframes mymove {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }
  .gsec3 {
    width: 100%;
    height: 100%;
    padding: 4rem 5rem;
    background-color: rgb(244, 240, 210);
    overflow: hidden !important;
    opacity: 1;
    animation: mymove1 5s;
  }
  @keyframes mymove1 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    85% {
      opacity: 0;
    }
    91% {
      opacity: 0.1;
    }
    92% {
      opacity: 0.2;
    }
    93% {
      opacity: 0.3;
    }
    94% {
      opacity: 0.4;
    }
    95% {
      opacity: 0.5;
    }
    96% {
      opacity: 0.6;
    }
    97% {
      opacity: 0.7;
    }
    98% {
      opacity: 0.8;
    }
    99% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
}
.profad {
  background: #f7fafc;
}
.prodhigh {
  position: absolute;
  left: 16.6658%;
  top: 0px;
  width: 150px;
  height: 150px;
}
.prodhigh1 {
  background-image: url();
  background-color: #adb5bd;
  background-size: auto 23px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 38px;
  height: 38px;
  border-radius: 25%;
  position: absolute;
  left: 15px;
  top: 17px;
  z-index: 2;
  opacity: 0.6;
  color: #fff;
  object-fit: contain;
  padding: 2px;
}

.prodhigh1:hover {
  background-color: white;
  opacity: 1;
}

.prodlights {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}
.prodlights1 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}
.prodlights2 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}

.prodlights3 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}

.prodlights4 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}

.prodlights5 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}

.prodlights6 {
  border-radius: 12px !important;

  background-size: 100% 100%;
}

.prodlights7 {
  border-radius: 12px !important;
  height: 14rem;

  background-size: 100% 100%;
}
.prodhigh2 {
  object-fit: contain;
  padding: 2px;
  i {
    display: none;
  }
}
.prodlights:hover,
.prodlights1:hover,
.prodlights2:hover,
.prodlights3:hover,
.prodlights4:hover,
.prodlights5:hover,
.prodlights6:hover,
.prodlights7:hover {
  .prodhigh2 {
    background-color: #adb5bd47;
    font-size: 15px;
    color: white;
    font-weight: 900;

    position: absolute;
    right: 15px;
    top: 11rem;
    z-index: 2;
    border-radius: 100px;

    background-image: url();
    background-repeat: no-repeat;
    background-size: 17px auto;
    background-position: 7px 6px;
    line-height: 1.05;
    padding: 7px 8px 7px 8px;
    overflow: hidden;
    transition: 0.3s background-color;
    animation: pushpulse 0.35s;

    .prodhigh1 {
      background-image: url();
      background-color: #adb5bd;
      background-size: auto 23px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      position: absolute;
      left: 15px;
      top: 17px;
      z-index: 2;
      opacity: 0.6;
      color: #fff;
    }
    i {
      display: block !important;
    }
  }
}

.prodhigh2:hover {
  opacity: 0 !important;
}
.prodhigh3 {
  width: 200px;
  height: 200px;
  border-radius: 6px;
  position: relative;
  background-color: aqua;
  z-index: 2;
}
.btn-outline-primary:hover {
  background-color: rgb(44, 157, 183) !important;
  border-color: rgb(44, 157, 183) !important;
  color: #fff !important;
}
.rprod {
  width: 100%;
  height: 100%;
}
.firimg {
  border-radius: 13px;
  overflow: hidden;
}
.badge-one {
  padding: 3px;
  font-size: 53%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-two {
  padding: 3px;
  font-size: 53%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.gfl-ribbon {
  display: inline-block;
  text-decoration: none;
  font-size: 9px;
  margin-right: 2px;
  font-weight: 700;
  color: #62656d;
  background-color: #fb634045;
  border-radius: 100px;
  /* padding: 3px 11px 3px 28px; */
  background-image: url();
  background-size: auto 75%;
  background-position: 7px 50%;
  background-repeat: no-repeat;
  padding: 4px;
}
.badge-one {
  position: absolute;
  top: -6px;
  height: 1rem;
  left: -8px;
}
.profad1 {
  background-color: #f0f0f0;
}
.card-profile-image img {
  max-width: 105px;
  border-radius: 0.375rem;
  border: 3px solid #fff;
  transform: translate(-50%, -50%) scale(1);
  position: absolute;
  left: 50%;
  transition: all 0.15s ease;
}
.table .thead-dark th {
  background-color: #2c9db7;
  color: #ffffff;
}
.table .thead-dark th {
  color: #f8f9fe;
  background-color: #2c9db7;
  border-color: #2c9db7;
}
.pre-0011 {
  border: 2px dashed #2c9db7;
  padding: 20px;
  border-radius: 10px;
}
.Blog_page {
  height: 30rem;
  img {
    width: 100px;
    height: 100px;
  }
}

//---------------------------------ProductList--------------------------------//
#product {
  .card-wrap {
    text-align: center;
  }

  .cards {
    width: 98%;
    height: auto;
    position: relative;
    //background: #fff;
    overflow: visible;
    /* margin: 20px; */
    //box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: block;
  }
  img {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
    z-index: 0;
    border-radius: 18px;
  }
  .set-image {
    width: 100%;
    height: 86%;
    //background: #eee;
    overflow: hidden;
    font: bold 20px Sans-Serif;
    text-transform: uppercase;
    display: table;
  }

  .set-image p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    color: #d2d2d2;
  }

  .set-headline {
    width: 90%;
    height: 5%;
    //background: #eee;
    margin: 10px;
    h3 {
      white-space: nowrap;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mainContainer {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
}

//-----------------------------------Channels----------------------------
.channeCardlimg {
  //border-bottom: 1px solid #eeeeee;
  object-fit: contain;
  max-height: 150px;
  width: 100%;
  vertical-align: top;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.channeldesc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardsHe {
  min-height: 530px;
  max-height: 530px;
}
.whytext {
  padding-top: 8.5rem;
}
.MuiAccordionSummary-content {
  display: block !important;
  color: rgb(40, 97, 99) !important;
}
.gsec12 {
  width: 100%;
  height: 608px;
  opacity: 0.8;
  right: 0;
  overflow: hidden;
  padding: 9.5rem 0;
  background: #d0ece9;
  -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 53%, 0% 0%);
  animation: mymove 5s;
  margin-top: 6rem;
}
.gsec31 {
  width: 100%;
  height: 100%;
  padding: 8rem 5rem;
  background-color: #f4f0d2;
  overflow: hidden !important;
  opacity: 1;
  animation: mymove1 5s;
}
.badge-warning {
  background-color: #adb5bd47 !important;
}
.badge-warning1 {
  background-color: white !important;
}

.txtwrp {
  white-space: nowrap;
  width: 277px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txtwrp1 {
  white-space: nowrap;
  width: 232px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gfl-product-content {
  padding: 11px 10px 0;
  overflow: hidden;
}
.tltp {
  background-color: white;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #4ca1af 0%,
    #c4e0e5 51%,
    #4ca1af 100%
  );
}
.btn-grad {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 18px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad1 {
  background-image: linear-gradient(
    to right,
    #4ecdc4 0%,
    #556270 51%,
    #4ecdc4 100%
  );
}
.btn-grad1 {
  margin-bottom: 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 18px;
  display: block;
  margin-left: -2rem;
}

.btn-grad1:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.nvhv {
  h4 {
    color: #62656d !important;
  }
}
.nvhv:hover {
  color: #47aafa !important;
  border-bottom: 2px solid #2b708b !important ;
}

.navImg {
  max-height: 60px;
  width: 100%;
  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 115px;
    max-height: 100px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    max-width: 115px;
    max-height: 100px;
  }
}
.nav-link {
  font-size: 14px !important;
}
#guestNav {
  padding: 0.2rem 1rem !important;
  @media screen and (max-width:600px) and (min-width:300px)
  {
    padding: 0 !important;
  }
}

.nav-item {
  cursor: pointer;
}
.navbar-nav a.nav-item {
  position: relative;
}
.navbar-nav a.nav-item:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #2ca5cd;
  height: 1px;
}
.navbar-nav a.nav-item:hover:after {
  width: 100%;
}
.activeLink {
  font-weight: 600 !important;
  color: #32325d !important;
}
.activeLink:after {
  width: 100% !important;
}
.navbar-nav a.nav-item,
.navbar-nav a.nav-item:after,
.navbar-nav a.nav-item:before {
  transition: all 0.5s;
}
.bg-nav {
  background-color: white !important;
}
//font-weight: 600;
.login {
  border: 1px #2ca5cd solid;
  border-radius: 5rem;
  background-color: white;
}
.signup {
  border: 1px #fb6340 solid;
  border-radius: 5rem;
}
#guestNav {
  i {
    margin-right: 0.5rem !important;
  }
  .fa-user-plus {
    color: #fb6340 !important;
  }
  .fa-sign-in-alt {
    color: #2ca5cd !important;
  }
}

//----------------------Blog--------------------------------->
.blog_card {
  padding-left: 0px;
}
.blog_card h1 {
  color: #2ca5cf;
  margin-top: 10px;
  margin-bottom: 0px;
}
.blog_card1 {
  height: 240px;
}
.blog_readmore {
  height: 25rem;
}
.blog_readmore i {
  color: #1890ff;
  padding-left: 40px;
}
.blog_readmore i:hover {
  cursor: pointer;
}
.Blog_page img {
  margin-left: 20px;
}
.blog_side {
  padding-left: 0px;
  padding-right: 0px;
}
.blog_side_header {
  height: 440px;
}
.blog_side h3 {
  color: #2ca5cf;
}
.blog_side1 {
  margin-left: 30px;
}
.blog_side2 {
  margin-top: 10px;
}
.side_list ul {
  padding-left: 0px;
  list-style: none;
}
.side_list1 img {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  padding-left: 0px;
}
.side_list2 {
  padding-left: 0px;
}
.side_list3 {
  padding-left: 0px 40px 0px 10px;
  color: black;
}
.side_list3 {
  font-size: 13px;
  padding-right: 15px;
}
.side_list4 {
  font-size: 13px;
}
.brnd-log {
  border: 1px solid #adb5bd;
  width: 55px;
  top: 10px;
  left: 10px;
  padding: 0.5rem;
  border-radius: 15px;
  height: 55px;
  position: absolute;
}
.brnd-log:hover {
  background-color: #ffffff78;
  border: 1px solid #ffff;
  opacity: 1;
}
.badge-1 {
  width: 100%;
  height: 1.5rem;
  padding: 8px;
  background-color: #2c9db7 !important;
  border-color: #2c9db7 !important;
  color: #ffff !important;
}
#Product {
  .outline,
  .fill {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 2;
  }
  .fill {
    color: #e64c3c;
  }
  .outline {
    color: rgb(168, 168, 171);
  }
  .outline:hover {
    color: #e64c3c;
  }
  .fill:hover {
    color: rgb(168, 168, 171);
  }
  .productIcon {
    i {
      font-size: 20px;
      position: absolute;
      opacity: 0.7;
    }

    .Icon1,
    .Icon2,
    .Icon3,
    .Icon4 {
      cursor: pointer;
      position: relative;
      width: 40px;
      padding: 9px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgb(238, 238, 238);
    }
    .Icon1:hover,
    .Icon2:hover,
    .Icon3:hover,
    .Icon4:hover {
      i {
        opacity: 1;
      }
    }
    .Icon2 {
      padding: 8px;
    }
    .Icon3 {
      padding: 10px;
    }
  }

  .awards {
    div {
      width: 55px;
      height: 55px;
      border: 1px solid #eee;
      margin-left: 1rem;
      border-radius: 0.375rem;
      img {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
        border-radius: 0.375rem;
      }
    }
  }

  .products-card {
    background: #fff;
    width: 100%;
    height: 28rem;
    position: relative;
    border-radius: 0.375rem;
    overflow: visible;
    margin: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: inline-block;

    .set-headline {
      width: 100%;
      height: 10%;
      // background: #eee;
      // margin: 20px; }
      h3 {
        border-bottom: 1px solid #eee;
        white-space: nowrap;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 7px;
      }
    }
    .set-headline1 {
      width: 100%;
      height: 10%;
      // background: #eee;
      // margin: 20px; }
      h3 {
        border-bottom: none;
        white-space: nowrap;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 35px;
      }
    }

    .brandImg {
      border-radius: 0.375rem;
      width: 65px;
      min-height: 65px;
      background: #fff;
      // position: absolute;
      // left: 10px;
      // top: 10px;

      border: solid 1px #eeeeee;
      img {
        border-radius: 0.375rem;
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
      }
    }

    .set-image {
      img {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
      }
      border-radius: 0.375rem;

      width: 100%;
      height: 230px;
      max-height: 230px;
      min-height: 200px;
      background: #fff;
      overflow: hidden;
      font: bold 20px Sans-Serif;
      text-transform: uppercase;
      // display: table;
      .product-title {
        width: 46%;
        text-align: center;
        overflow: hidden;
        height: 80%;
        padding: 28px;
        display: inline-block;
        position: absolute;
      }
    }

    .set-image p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      line-height: 25px;
      color: #d2d2d2;
    }
  }
  .ribbon-wrapper-3 {
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    left: -6px;
  }

  .ribbon-3 {
    font: bold 15px Sans-Serif;
    line-height: 18px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    left: -33px;
    top: 26px;
    width: 150px;
    background-color: #2c9db7;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
  }

  .ribbon-3:before,
  .ribbon-3:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  .ribbon-3:before {
    left: 0;
  }

  .ribbon-3:after {
    right: 0;
  }
}
.playbtnrec {
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 59%;
  left: 45%;
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    top: 60% !important;
    left: 44% !important;
  }
  @media only screen and (max-width: 1365px) and (min-width: 1025px) {
    top: 56% !important;
    left: 44% !important;
  }

  @media only screen and (device-width: 1366px) {
    top: 56% !important;
    left: 45% !important;
  }
  @media only screen and (max-width: 600px) {
    top: 70% !important;
    left: 32% !important;
  }

  @media only screen and (device-width: 2560px) {
    top: 58% !important;
    left: 46% !important;
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      left: 57% !important;
    }
  }
  @media only screen and (device-width: 1680px) {
    top: 56% !important;
    left: 46% !important;
  }
  @media only screen and (device-width: 1440px) {
    top: 56% !important;
    left: 44% !important;
  }
  @media only screen and (device-width: 1920px) {
    top: 59% !important;
    left: 45% !important;
  }
  //   @media only screen and (device-width: 1600px)
  // {
  //   top: 42% !important;
  //   left: 55% !important;
  // }
  @media only screen and (device-width: 2048px) {
    top: 42% !important;
    left: 54% !important;
  }
  // @media (min-width:1440px) and (max-resolution: 62dpi) {
  //   top: 42% !important;
  //   left: 50% !important;
  // }
}

.playbtn {
  // display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 42%;
  width: 100%;
  justify-content: center;
  display: flex;
  // left: 55%;
  @media only screen and (max-width: 1023.96px) and (min-width: 900px) {
    top: 41% !important;
    // left: 69% !important;
  }
  @media only screen and (device-width: 1024px)
  {
    top: 32% !important;
    // left: 39% !important;
  }
  @media only screen and (max-width: 800.96px) and (min-width: 700px) {
    top: 39% !important;
    left: 0% !important;
  }
  @media only screen and (max-width: 1365px) and (min-width: 1025px) {
    top: 42% !important;
    // left: 49% !important;
  }

  @media only screen and (device-width: 1366px) {
    top: 42% !important;
    // left: 50% !important;
  }
  @media only screen and (max-width: 600px) and (min-width: 400px) {
    top: 23% !important;
    // left: 36% !important;
  }

  @media only screen and (max-width: 400.96px) and (min-width: 350.96px){
    top: 23% !important;
    // left: 34% !important;
  }

  @media only screen and (max-width: 349.96px) and (min-width:319px) {
    top: 15% !important;
    // left: 30% !important;
  }
  @media only screen and (device-width:820px)
  {
    top: 42% !important;
    // left: 44% !important;
  }

  // @media only screen and (device-width: 2560px) {
  //   top: 42% !important;
  //   left: 45% !important;
  //   @media screen and (-webkit-min-device-pixel-ratio: 0) {
  //     left: 45% !important;
  //   }
  // }
  @media only screen and (device-width: 1680px) {
    top: 38% !important;
    // left: 57% !important;
  }
  @media only screen and (device-width: 1440px) {
    top: 36% !important;
    // left: 43% !important;
  }
  @media only screen and (device-width: 1920px) {
    top: 43% !important;
    // left: 71% !important;
  }
  @media only screen and (device-width: 1980px) {
    top: 12rem !important;
  }
  //   @media only screen and (device-width: 1600px)
  // {
  //   top: 42% !important;
  //   left: 55% !important;
  // }
  @media only screen and (device-width: 2048px) {
    top: 42% !important;
    // left: 54% !important;
  }
  // @media (min-width:1440px) and (max-resolution: 62dpi) {
  //   top: 42% !important;
  //   left: 50% !important;
  // }
}

// @media only screen and (min-width: 1920px){
//   position: absolute;
//     z-index: 100;
//     left: -3rem;
//     bottom: 12rem;

// }
//   @media only screen and (max-width: 1360px) and (min-width: 1200px){
//     left: -3rem;
//     bottom: 11rem;
//   }
//   @media only screen and (max-width: 1599px) and (min-width: 1441px){
//     left: -3rem;
//     bottom: 10rem;
//   }
//   @media only screen and (max-width: 1700px) and (min-width: 1610px){
//     left: 5rem;
//     bottom: 5rem;
//   }
//   @media only screen and (max-width: 1800px) and (min-width: 1710px){
//     left: 6rem;
//     bottom: 5rem;
//   }
//   @media only screen and (max-width: 1920px) and (min-width: 1810px){
//     left: 6rem;
//     bottom: 7rem;
//   }
//   left: 9rem;
//     bottom: 5rem;
//   @media only screen and (max-width: 2030px) and (min-width: 1921px){
//     left: -2rem;
//     bottom: 10rem;
//   }
//   @media only screen and (max-width: 2220px) and (min-width: 2031px){
//     left: 1rem;
//     bottom: 10rem;
//   }
//   @media only screen and (max-width: 2220px) and (min-width: 2031px){
//     left: 1rem;
//     bottom: 10rem;
//   }
//   @media only screen and (max-width: 2652px) and (min-width: 2221px){
//     left: 8rem;
//     bottom: 10rem;
//   }

.smallplay {
  position: absolute;
  top: 20rem;
  @media only screen and (min-width: 1920px) {
    top: 25rem;
  }
}
.titleVid {
  top: 23%;
  /* position: relative; */
  left: 3%;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 0;
  line-height: 1;
}
.playhead {
  width: 45%;
  position: absolute;
  top: 15rem;
  margin-left: 3rem;
  @media only screen and (device-width: 1440px) {
    // top: 28rem !important;
    margin-left: 2rem !important;
    width: 44% !important;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    top: 6rem !important;
    padding-right: 7rem !important;
  }
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    width: 85% !important;
    margin-left: 2rem !important;
    top: 5rem !important;
  }
  @media only screen and (device-width:1024px)
  {
    top: 30% !important;
    margin-left: 2rem !important;
  }
  @media only screen and (device-width: 1680px) {
    margin-left: 4rem !important;
    top: 7rem !important;
  }
  @media only screen and (device-width: 1920px) {
    top: 5rem !important;
    margin-left: 7.5rem !important;
    width: 40% !important;
  }
  @media only screen and (device-width:1980px) {
   top: unset !important;
   
  }

  @media only screen and (device-width: 1600px) {
    top: 10rem;
    width: 44% !important;
  }
  @media only screen and (device-width: 2560px) {
    top: 25rem !important;
    margin-left: 6.5rem !important;
  }
  @media only screen and (device-width: 1366px) {
    width: 44% !important;
  }
  @media only screen and (device-width: 1360px) {
    width: 44% !important;
  }
  @media screen and (max-width:500px) {
    width: 70% !important;
    padding-right: 0 !important;
    margin-left: 2rem !important;
  }
}

.button-text-primaryry1:hover {
  color: #696970;
  border-color: #6fd4db;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.button-text-primaryry1 {
  display: inline-block;
  font-weight: 700;
  font-size: 1.125rem;
  padding-bottom: 0.625rem;
  border-bottom-width: 3px;
  border-style: solid;
  transition-duration: 0.3s;
  border-top: hidden;
  border-left: hidden;
  border-right: none;
  position: absolute;
  left: 37px;
  top: -2px;
  width: 16rem;
  color: darkgray;
}
.playbtn1 {
  position: absolute;
  z-index: 100;
  left: -3rem;
  bottom: 7rem;
  cursor: pointer;
  @media only screen and (min-width: 1920px) {
    position: absolute;
    z-index: 100;
    left: -3rem;
    bottom: 12rem;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1400px) {
    position: absolute;
    z-index: 100;
    left: -3rem;
    bottom: 9rem;
  }
}
.round2-bg {
  width: 250px;
  height: 250px;
  top: -88px;
  left: 221px;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  // background: #2c9db7;
  @media all and (max-width: 599px) {
    width: 150px;
    height: 150px;
    top: -9px;
    left: 60px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    // background: #2c9db7;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    width: 150px;
    height: 150px;
    top: -9px;
    left: 60px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    // background: #2c9db7;
  }
}

.round5-bg {
  width: 250px;
  height: 250px;
  top: 190px;
  left: 230px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  // background: #2e9eb75e;

  @media all and (max-width: 599px) {
    width: 150px;
    height: 150px;
    top: 122px;
    left: 114px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    // background: #2e9eb75e;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    width: 150px;
    height: 150px;
    top: 122px;
    left: 114px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    // background: #2e9eb75e;
  }
}

.bx-shadow {
  // box-shadow: 3px 0 21px #bfbfbf;
  padding: 20px;
  border-radius: 5px;
  // background-color: #fff;
  position: relative;
  z-index: 99;
}
.service-list-bx {
  text-align: center;
  padding: 26px 11px !important;
  min-height: 135px;
  height: 19rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 15px 13px 21px;
  width: 27rem;
  top: -4rem;
  @media only screen and (device-width: 1366px) {
    width: 27rem !important;
  }
  @media only screen and (device-width: 1024px) {
    width: 23rem !important;
    height: 18rem !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  @media all and (max-width: 599px) {
    text-align: center;
    // background-color: #fff;
    padding: 18px 11px !important;
    min-height: 135px;
    height: 9rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 15px 13px 21px;
    width: 13rem;
    top: -3rem;
    left: 2rem;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    text-align: center;
    // background-color: #fff;
    padding: 18px 11px !important;
    min-height: 135px;
    height: 9rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 15px 13px 21px;
    width: 13rem;
    top: -3rem;
  }
}
.bx-shadow1 {
  // box-shadow: 3px 0 21px #bfbfbf;
  padding: 20px;
  border-radius: 5px;
  // background-color: #fff;
  position: relative;
  z-index: 99;
}
.mobileSection {
  display: none !important;
}
@media only screen and (max-width: 1023px) and (min-width: 320px) {
  .mobileSection {
    position: relative;
    top: -3rem;
    display: flex !important;
  }
  .desSection {
    display: none !important;
  }
}

.service-list-bx1 {
  text-align: center;
  // background-color: #fff;
  padding: 18px 11px !important;
  min-height: 135px;
  height: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 15px 13px 21px;
  position: relative;
  top: -10.5rem;
  width: 100%;
  left: -2rem;
  @media only screen and (min-width: 1600px) and (max-width: 1680px) {
    left: -4rem !important;
  }
  @media only screen and (device-width: 2560px) {
    left: -17rem !important;
  }
  @media only screen and (device-width: 1920px) {
    left: -8rem !important;
  }
  @media all and (max-width: 599px) {
    text-align: center;
    // background-color: #fff;
    padding: 18px 11px !important;
    min-height: 135px;
    height: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 15px 13px 21px;
    position: relative;
    top: -5.5rem;
    width: 12rem;
    left: -2rem;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    text-align: center;
    // background-color: #fff;
    padding: 18px 11px !important;
    min-height: 135px;
    height: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 15px 13px 21px;
    position: relative;
    top: -5.5rem;
    width: 12rem;
    left: -2rem;
  }
}
.vdist {
  height: 30rem;
  width: 100%;
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    height: 60rem !important;
    margin-top: 0rem !important;
  }
  @media all and (max-width: 599px) {
    height: 47rem !important;
    margin-top: 0rem !important;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    height: 47rem !important;
    margin-top: 0rem !important;
  }
}
.vendist {
  height: 30rem;
  // border: 1px solid red;
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    margin-left: 4rem !important;
  }
}

.pad_gue {
  padding-left: 12rem;
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    padding-left: 4rem;
    margin-bottom: 6rem !important;
  }
  @media all and (max-width: 599px) {
    padding-left: 0rem !important;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    padding-left: 0rem !important;
  }

  @media only screen and (device-width: 1024px) {
    padding-left: 0rem !important;
  }
}

.pad-dat {
  padding-top: 4rem;
}
.plrl {
  padding-right: 8rem;
  padding-left: 8rem;
  @media only screen and (max-width: 1600px) and (min-width: 900px) {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  @media only screen and (max-width: 1023px) and (min-width: 765px) {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  @media all and (max-width: 599px) {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  @media all and (min-width: 600px) and (max-width: 765px) {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
}

.list-group-item {
  border: none !important;
}
.clrvari {
  background-color: #f8f9fe !important;
  .list-group-item {
    background-color: #f8f9fe !important;
  }
}
.hero-text-about1 {
  h1 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.5rem;
  }
}
.list-group-item1 {
  position: relative;
  display: block;
  padding: 0.25rem;
  /* background-color: #fff; */
  // border: 1px solid #e9ecef;
}
.contactTitle1 {
  font-size: 2rem;
  @media only screen and (max-width: 750px) and (min-width: 300px) {
    font-size: 1.5rem;
  }
}
.borderfull1 {
  border: 1px solid #2c9db7;
  padding: 20px 40px;
  position: relative;
}
.borderfull1::before {
  // content: url(../../img/guestpage/lock-svg.png);
  position: absolute;
  top: -1px;
  left: -2px;
  padding-right: 5px;
  background-color: #ffffff;
}
.borderfull1::after {
  // content: url(../../img/guestpage/lock-svg.png);
  position: absolute;
  bottom: -9px;
  right: -3px;
  padding-left: 8px;
  padding-top: 5px;
  background-color: #ffffff;
}
.borderfull1 h4 {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.borderfull1 p {
  margin: 15px auto 35px auto;
  text-align: center;
  padding: 1rem;
}
.ccconect {
  cursor: pointer;
}
.prodspecspan {
  @media only screen and (max-width: 1920px) and (min-width: 1600px) {
    margin-left: 13rem !important;
  }
  @media only screen and (max-width: 2560px) and (min-width: 1921px) {
    margin-left: 32rem !important;
  }
}
#pprodh {
  @media only screen and (device-width: 2560px) {
    margin-top: 2.5rem !important;
    h1 {
      font-size: 3.625rem !important;
    }
    h5 {
      font-size: 2.5rem !important;
    }
    .price {
      font-size: 4rem !important;
    }
  }

  @media only screen and (device-width: 1440px) {
    margin-top: 2.5rem !important;
    h1 {
      font-size: 1.15rem !important;
    }
    h5 {
      font-size: 1.15rem !important;
    }
  }
}
// @media only screen and (device-width: 1440px){
//   .prodcompanytitle{
//     font-size: 2.25rem !important
//   }
// }
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  // padding-left: 65px !important;
  @media only screen and (max-width: 764px) {
    padding-right: 54px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    // width: 102px;
  }
  @media only screen and (device-width: 414px) {
    padding-right: 54px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 128px;
  }
}
.topicon {
  img {
    width: 36px !important;
    max-height: 36px !important;
  }
}
