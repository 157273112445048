.span-001{
    position: absolute;
    width: 11rem;
    margin-top: 5.5rem;
    margin-left: -30rem;
    max-height: 2rem;
    border: none;
    background-color: #9acd3200;
    color: #ffff;
}
.MuiAvatar-root {
    width: 85px !important;
    height: 85px !important;
}


// .PrivateTabIndicator-root-2 {
//     left: 426.047px !important;
//     width: 23.891px !important;
//     top: 122px !important;
//     height: 24px !important;
//     transform: rotate(45deg) !important;
// }
.PrivateTabIndicator-colorSecondary-4 {
    background-color: #ffff !important;
}


.remove-arrow{
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
input[type=number] {
-moz-appearance: textfield;
  }
}
}
.btn-001{
  width: 100%;
  text-align: center;
}
// .makeStyles-root-1{
//   padding-top: 14rem !important;
// }
// .MuiTabs-root {
//   padding-top: 4rem !important;
//   min-height: 15rem !important;
// }
.header-span{
  width: 105%;
  top: 7rem;
  left: -3rem;
  position: absolute;
  color: #ffff;
}
.header-span1{
  width: 100%;
  top: 7rem;
  left: 0.8rem;;
  text-align: center;
  position: absolute;
  color: #ffff;
}
.head-img{
  width: 100px;
  margin-left: 1.5rem;
}
.header-icon{
  position: absolute;
  top: 3rem;
}
.header-center{
height: 15rem;
padding-top: 4rem;
}
.main-head{
  padding: 12rem 0px 0px !important;
  background-color: blue;
}
.row{
  margin: 0px !important;
}
.col-001{
  opacity: 0.5;
}
.col-001:hover{
  opacity: 1 !important;
}

//for stepper label color
.stepper-label{
  color: white;
}

.input-suggestion{
  font-family: 'Lato', sans-serif;
  // width: 100%;
  height: 60px;
  background-color: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(70, 69, 69);
  margin-right: 2rem;
  // position: absolute;
  // margin-left: 5rem;
}
.canvas{
  // height: 100vh;
  // background-image: url(https://images.pexels.com/photos/802412/pexels-photo-802412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-size: cover;
  background-position: center;
}

.makeStyles-instructions-3 {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16rem;}

  .mobotp{
    text-align: center;
  }

  .MuiAppBar-colorDefault {
    color: rgba(0, 0, 0, 0.87);
    background-color: #11cdef;
}

.hidden{
  display: none;
}
